@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        @apply font-montserrat;
    }
}

@layer components {
    .test-class {
        @apply text-lg font-bold text-red-400;
    }

    .heading-black {
        @apply text-2xl sm:text-3xl text-jet-black font-bold uppercase;
    }

    .heading-blue {
        @apply text-2xl sm:text-3xl text-dark-blue font-bold uppercase;
    }

    .paragraph {
        @apply text-base sm:text-lg text-jet-black;
    }
}

#indicators-carousel [data-carousel-slide-to][aria-current="true"] {
    @apply bg-blue-500;
}

#indicators-carousel [data-carousel-slide-to] {
    @apply bg-blue-400;
}

.iti {
    @apply w-full block;
}

.article ul,
.article ol {
    padding: revert;
    list-style: revert;
}

[x-cloak] {
    display: none !important;
}
